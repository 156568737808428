import { IAuPaginated } from '@shared/ui-components/au-paginator/pagination-page.info';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';
import { IViewEntityTransformerData } from '@app/pages/views/interfaces/entity-types.interface';
import { AuUtilsFunctions } from '@app/shared';
import { IIncident } from '@app/pages/cmms/interfaces/incidents.interface';
import { IRequest } from '@app/pages/cmms/interfaces/request.interfaces';
import { IFileEntity } from '@app/pages/cmms/interfaces/files.interfaces';
import { ICmmsAsset } from '@app/pages/cmms/interfaces/assets.interfaces';

export function defaultTransform(data: any) {
	return data;
}

export function transformWorkOrdersData(
	data: IAuPaginated<IWorkOrder>
): IViewEntityTransformerData {
	const workOrders = AuUtilsFunctions.extractNodes(data.edges).map(item => {
		const workOrder = { ...item };
		workOrder.incidentWorkOrders = AuUtilsFunctions.extractNodes(
			workOrder.incidentWorkOrders.edges
		);
		workOrder.requestWorkOrders = AuUtilsFunctions.extractNodes(
			workOrder.requestWorkOrders.edges
		);

		return workOrder;
	});

	return {
		entityData: workOrders,
		entityTablePagination: {
			pageInfo: data.pageInfo,
			totalCount: data.totalCount,
		},
	};
}

export function transformIncidentsData(
	data: IAuPaginated<IIncident>
): IViewEntityTransformerData {
	const incidents = AuUtilsFunctions.extractNodes(data.edges).map(item => {
		const incident = { ...item };

		incident.requestIncident = AuUtilsFunctions.extractNodes(
			incident.requestIncident.edges
		);

		return incident;
	});

	return {
		entityData: incidents,
		entityTablePagination: {
			pageInfo: data.pageInfo,
			totalCount: data.totalCount,
		},
	};
}

export function transformRequestsData(
	data: IAuPaginated<IRequest>
): IViewEntityTransformerData {
	const requests = AuUtilsFunctions.extractNodes(data.edges);

	return {
		entityData: requests,
		entityTablePagination: {
			pageInfo: data.pageInfo,
			totalCount: data.totalCount,
		},
	};
}

export function transformFilesData(
	data: IAuPaginated<IFileEntity>
): IViewEntityTransformerData {
	const files = AuUtilsFunctions.extractNodes(data.edges);

	return {
		entityData: files,
		entityTablePagination: {
			pageInfo: data.pageInfo,
			totalCount: data.totalCount,
		},
	};
}

export function transformAssetsData(
	data: IAuPaginated<ICmmsAsset>
): IViewEntityTransformerData {
	// It seems like necessary to clone it
	// Use the any type because here we are modifying the default type.
	// This tricky solution is necessary to simplify pagination of the data that is contained in classificationAttachedFileEntities
	// Important! if we are remove any type it can be work, but during test we have exception for this function
	const dataClone: any = structuredClone(data);
	const assets = dataClone.edges.map(edge => {
		if (edge.node.classificationAttachedFileEntities) {
			edge.node.classificationAttachedFileEntities =
				edge.node.classificationAttachedFileEntities.edges.map((edge: any) => edge.node);
		}

		return edge.node;
	});

	return {
		entityData: assets,
		entityTablePagination: {
			pageInfo: data.pageInfo,
			totalCount: data.totalCount,
		},
	};
}
