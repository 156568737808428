import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { EntityTypesConfig } from '@app/pages/views/configs/entity-types.config';
import { TableType } from '@app/pages/cmms/types';
import { map } from 'rxjs/operators';
import { ViewEntityDataType } from '@app/pages/views/state/views.reducer';
import { PaginationPageInfo } from '@shared/ui-components/au-paginator/pagination-page.info';
import { ICopyUiViewFolderPayload } from '@app/pages/views/interfaces/views-folder.interface';
import { CopyUiViewFolderMutation } from '@app/pages/views/graphql/views.mutations';

@Injectable({
	providedIn: 'root',
})
export class ViewEntitiesGqlService {
	private readonly apollo = inject(Apollo);

	loadEntityData(
		entityType: TableType,
		variables: any
	): Observable<{
		entityData: ViewEntityDataType[];
		entityTablePagination: { pageInfo: PaginationPageInfo; totalCount: number };
	}> {
		const config = EntityTypesConfig[entityType];
		if (!config) {
			return throwError(() => new Error(`Entity type ${entityType} is not supported`));
		}
		return this.apollo
			.use('federation')
			.query<typeof config.queryType>({
				query: config.loadQuery,
				variables: variables,
				fetchPolicy: 'network-only',
			})
			.pipe(
				map(response => response.data[config.pluckDataKey]),
				map(response => config.transform(response))
			);
	}

	copyUiViewFolder(variables: ICopyUiViewFolderPayload) {
		return this.apollo
			.use('federation')
			.mutate<{ CopyUIViewFolder: { uiViewFolders: any } }>({
				mutation: CopyUiViewFolderMutation,
				variables,
			})
			.pipe(map(response => response.data.CopyUIViewFolder));
	}
}
