import gql from 'graphql-tag';
import { ViewFolderFragment } from '@app/pages/views/graphql/views.queries';

export const CreateViewFolderMutation = gql`
	mutation CreateViewFolderMutation(
		$buildingId: UUID
		$name: String!
		$description: String
	) {
		createUiViewFolder(buildingId: $buildingId, name: $name, description: $description) {
			success
			uiViewFolder {
				...ViewFolderFragment
			}
		}
	}
	${ViewFolderFragment}
`;

export const UpdateViewFolderMutation = gql`
	mutation UpdateViewFolderMutation($pk: UUID!, $name: String, $description: String) {
		updateUiViewFolder(pk: $pk, name: $name, description: $description) {
			success
			uiViewFolder {
				...ViewFolderFragment
			}
		}
	}
	${ViewFolderFragment}
`;

export const DeleteViewFoldersMutation = gql`
	mutation DeleteViewFoldersMutation($pks: [UUID]!) {
		deleteUiViewFolder(folders: $pks) {
			success
		}
	}
`;

export const DeleteUiTableViewMutation = gql`
	mutation DeleteUiTableViewMutation($pk: UUID!) {
		deleteUiTableViewConfig(pk: $pk) {
			success
		}
	}
`;

export const CreateViewInsideFolderMutation = gql`
	mutation CreateViewInsideFolderMutation(
		$columnConfigs: JSONString
		$filterConfigs: JSONString!
		$isDefault: Boolean
		$name: String!
		$tableType: TableTypeEnum!
		$viewFolder: UUID
	) {
		createUiTableViewConfig(
			columnConfigs: $columnConfigs
			filterConfigs: $filterConfigs
			isDefault: $isDefault
			name: $name
			tableType: $tableType
			viewFolder: $viewFolder
		) {
			success
			uiTableViewConfig {
				columnConfigs
				createdBy {
					contentType
					firstName
					lastName
					name
					psId
					smallAvatarUrl
					smallLogoUrl
				}
				filterConfigs
				isDefault
				name
				pk
				uiTable {
					tableType
				}
				viewFolder {
					pk
				}
			}
		}
	}
`;

export const UpdateViewInsideFolderMutation = gql`
	mutation UpdateViewInsideFolderMutation(
		$columnConfigs: JSONString
		$filterConfigs: JSONString
		$isDefault: Boolean
		$name: String!
		$pk: UUID!
	) {
		updateUiTableViewConfig(
			columnConfigs: $columnConfigs
			filterConfigs: $filterConfigs
			isDefault: $isDefault
			name: $name
			pk: $pk
		) {
			success
			uiTableViewConfig {
				columnConfigs
				createdBy {
					contentType
					firstName
					lastName
					name
					psId
					smallAvatarUrl
					smallLogoUrl
				}
				filterConfigs
				isDefault
				name
				pk
				uiTable {
					tableType
				}
				viewFolder {
					pk
				}
			}
		}
	}
`;

export const CopyUiViewFolderMutation = gql`
	mutation CopyUiViewFolder(
		$buildings: [UUID]!
		$description: String
		$folders: [UUID]!
		$name: String
	) {
		copyUiViewFolder(
			buildings: $buildings
			description: $description
			folders: $folders
			name: $name
		) {
			success
			uiViewFolders {
				...ViewFolderFragment
			}
		}
	}
	${ViewFolderFragment}
`;
