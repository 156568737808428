import gql from 'graphql-tag';

export const ViewFolderFragment = gql`
	fragment ViewFolderFragment on UIViewFolderType {
		buildingId
		description
		name
		organizationPsId
		createdBy {
			contentType
			firstName
			lastName
			name
			psId
			smallAvatarUrl
			smallLogoUrl
		}
		pk
		uiTableViewConfigs {
			edges {
				node {
					pk
					name
				}
			}
		}
	}
`;

export const ViewFoldersQuery = gql`
	query ViewFoldersQuery(
		$buildingId: UUID
		$buildingId_In: [UUID]
		$buildingId_IsNull: Boolean
		$locations: [UUID]
		$search: String
		$onlyCount: Boolean = false
	) {
		uiViewFolders(
			buildingId: $buildingId
			buildingId_In: $buildingId_In
			buildingId_Isnull: $buildingId_IsNull
			locations: $locations
			search: $search
		) {
			edges @skip(if: $onlyCount) {
				node {
					...ViewFolderFragment
				}
			}
			totalCount
		}
	}
	${ViewFolderFragment}
`;

export const FolderViewQuery = gql`
	query FolderViewQuery($pk: UUID!) {
		uiTableViewConfig(pk: $pk) {
			columnConfigs
			filterConfigs
			isDefault
			name
			uiTable {
				tableType
			}
			createdBy {
				contentType
				firstName
				lastName
				name
				psId
				smallAvatarUrl
				smallLogoUrl
			}
			pk
			viewFolder {
				pk
			}
		}
	}
`;
