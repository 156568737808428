import { EntityConfigMap } from '@app/pages/views/interfaces/entity-types.interface';
import { TableType } from '@app/pages/cmms/types';
import { WorkOrdersQuery } from '@app/pages/cmms/graphql/work-order.queries';
import {
	transformAssetsData,
	transformFilesData,
	transformIncidentsData,
	transformRequestsData,
	transformWorkOrdersData,
} from '@app/pages/views/utils/etity-type.transformers';
import { IncidentsQuery } from '@app/pages/cmms/graphql/incident.queries';
import { RequestsQuery } from '@app/pages/cmms/graphql/requests.queries';
import { FilesQuery } from '@app/pages/cmms/graphql/file.queries';
import { AssetsQuery } from '@app/pages/cmms/graphql/assets.queries';
import { IWorkOrdersPaginatedData } from '@app/pages/cmms/interfaces/work-order.interface';
import { IIncidentPaginatedData } from '@app/pages/cmms/interfaces/incidents.interface';
import { IRequestsPaginatedData } from '@app/pages/cmms/interfaces/request.interfaces';
import { IFilesWithPaginationData } from '@app/pages/cmms/interfaces/files.interfaces';
import { ICmmsAssetPaginatedData } from '@app/pages/cmms/interfaces/assets.interfaces';

export const EntityTypesConfig: EntityConfigMap = {
	[TableType.WORK_ORDER]: {
		loadQuery: WorkOrdersQuery,
		transform: transformWorkOrdersData,
		queryType: {} as IWorkOrdersPaginatedData,
		pluckDataKey: 'workOrders',
	},
	[TableType.INCIDENTS]: {
		loadQuery: IncidentsQuery,
		transform: transformIncidentsData,
		queryType: {} as IIncidentPaginatedData,
		pluckDataKey: 'incidents',
	},
	[TableType.REQUESTS]: {
		loadQuery: RequestsQuery,
		transform: transformRequestsData,
		queryType: {} as IRequestsPaginatedData,
		pluckDataKey: 'requests',
	},
	[TableType.FILES]: {
		loadQuery: FilesQuery,
		transform: transformFilesData,
		queryType: {} as IFilesWithPaginationData,
		pluckDataKey: 'files',
	},
	[TableType.ASSETS]: {
		loadQuery: AssetsQuery,
		transform: transformAssetsData,
		queryType: {} as ICmmsAssetPaginatedData,
		pluckDataKey: 'assets',
	},
};
